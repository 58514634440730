@import "../variables";

.contact-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $color-white;
  box-shadow: $box-shadow-around;
  border-radius: $border-radius;
  @include setExpanderPaddings();
  @include setGap("medium");
}

.contact-header-wrapper {
  display: flex;
  flex-direction: column;
  @include setInnerPaddings("sm", false, false, "sm", "sm");
}

.contact-header {
  display: flex;
  align-items: flex-end;
  color: $color-darkblue;
  gap: 0.4em;
}
.contact-subtitle {
  color: $color-darkblue;
}

.contact-card-header-icon {
  font-size: 1.25em;
  color: $color-darkblue;
  padding-bottom: 2px;
}

.contact-card-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @include setInnerPaddings(false, false, false, "sm", "sm");
  @include setGap("medium");
}

@media only screen and (min-width: $width-m) {
  .contact-card-header-icon {
    padding-bottom: 3px;
  }
  .contact-card-content-wrapper,
  .contact-header-wrapper {
    padding: 0px;
  }
}

//Contact card contact
.contact-card-contact {
  .contact-card-persons {
    .contact-card-content-wrapper {
      @include setGap("large");
    }
  }
  .contact-card-location-map-wrapper {
    width: 100%;
    max-width: 600px;
    height: 360px;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  @media only screen and (min-width: $width-xs) {
    .contact-card-location-map-wrapper {
      height: 400px;
    }
  }
  @media only screen and (min-width: $width-m) {
    .contact-card-persons {
      .contact-card-content-wrapper {
        padding-top: 8px;
      }
    }
  }
  @media only screen and (min-width: $width-l-plus) {
    width: calc(50% - 12px);

    .contact-card-location-map-wrapper {
      min-height: 400px;
      height: 100%;
    }
  }
}

//Contact card pricing
.contact-height1
{
  height: 60%;
}
.contact-height2{
  height: 35%;
  margin-top: 4%;
}
.contact-card-pricing {
  .contact-header-wrapper {
    @include setGap("small");
  }
  .contact-card-content-wrapper {
    max-width: 320px;
  }

  &.contact-card-pricing-call {
    width: 100%;
    @include setGap("small");

    .contact-card-content-wrapper {
      max-width: 100%;
      padding-top: 4px;
      @include setGap("extrasmall");
    }
  }

  @media only screen and (max-width: $width-max-xxs) {
    .contact-subtitle {
      font-size: 17px;
    }
  }
  @media only screen and (min-width: $width-m) {
    width: calc(50% - 12px);

    .contact-card-content-wrapper {
      padding-top: 8px;
    }
  }
}
